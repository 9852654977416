import React, { useState } from 'react';
import { AiOutlineExpand } from 'react-icons/ai';
import { FullScreenIconDiv } from '../FullScreenIconDiv/FullScreenIconDiv';
import { gaLogEvent } from '../../../../apis/analytics';

interface Props {
  image: {
    id: string;
    path: string;
  };
  hoveredMarkerId: string;
  onImageExpand: (id: string) => void;
}

export const GalleryImage = ({
 image,
 hoveredMarkerId,
 onImageExpand
}: Props) => {

  const [ imageLoaded, setImageLoaded ] = useState(false);

  return (
    <>
      <img
        style={{
          width: '100%',
          height: 'auto',
          boxShadow: image.id === hoveredMarkerId ? '0px 0px 6px 2px #FFFFFF' : '',
          display: 'block',
        }}
        src={image.path}
        alt={`Failed to load ${image}`}
        onLoad={() => setImageLoaded(true)}
      />
      {
        imageLoaded &&
        <FullScreenIconDiv
          onClick={(e) => {
            e.stopPropagation();
            gaLogEvent({ category: 'trail-view', action: 'full-screen-click' });
            onImageExpand(image.id.toString());
          }}
        >
          <AiOutlineExpand
            size="32px"
          />
        </FullScreenIconDiv>
      }
    </>
  );

};
