import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  useParams
} from 'react-router-dom';
import { TrailMap } from '../trail-map/TrailMap';
import { Feature, FeatureCollection, LineString, Point } from 'geojson';
import { fetchTrail, fetchTrailImages } from '../../apis/trailepics-api';
import { buildTrailWaypoints, findClosestPointToLngLat, isNullish } from '../../utilities/utilities';
import { fetchTrailGeoJson } from '../../apis/cloudstorage';
import { TrailImage } from '../../apis/trailepics-api.models';
import { ImageGallery } from '../image-gallery/ImageGalley';
import { ImageOverlay } from '../image-overlay/ImageOverlay';
import { useMediaQuery } from 'react-responsive';
import { ScreenSize } from '../../App.viewmodels';
import { RouteTimeline } from '../route-timeline/RouteTimeline';
import { LngLat } from 'mapbox-gl';
import { gaLogEvent } from '../../apis/analytics';

const DEFAULT_TRAIL_ID = '1';
const MED_SCREEN_IMG_GALLERY_WIDTH = 200;
const DEFAULT_IMG_GALLERY_WIDTH = 400;

/*
 * A mechanism to build the different view configs, but memoize the object for different input args.
 * Thereby every render won't have a new object and change the style.
 * Note that there might be redundant values
 * Large screen { small: false, medium: false }
 * Medium screen { small: false, medium: true }
 * Small screen { small: true, medium: true }
 * isMobile === medium
 */
const cachedSizes: {[key: string]: ScreenSize} = {};
const getScreenSize = (isSmall: boolean, isMedium: boolean) => {
  const key = `[${isSmall} ${isMedium}]`;
  if (!cachedSizes[key]) {
    cachedSizes[key] = {
      small: isSmall,
      medium: isMedium
    };
  }
  return cachedSizes[key];
};

interface UrlParams {
  trailId: string;
}

export const TrailView = () => {

  let { trailId } = useParams<UrlParams>();
  trailId = isNullish(trailId) ? DEFAULT_TRAIL_ID : trailId;
  const [ trail, setTrail ] = useState<FeatureCollection | null>(null);
  const [ trailImages, setTrailImages ] = useState<TrailImage[]>([]);
  // Using a reference variable re-triggers the effects that runs when this variable changes.
  const [ clickedMarkerId, setClickedMarkerId ] = useState<[string]>(['']);
  const [ hoveredMarkerId, setHoveredMarkerId ] = useState('');
  const [ forcedLngLat, setForcedLngLat ] = useState<[number, number] | null>(null);
  const [ fullscreenImageIndex, setFullscreenImageIndex ] = useState<number>(0);
  const [ showFullScreen, setShowFullScreen ] = useState(false);
  const [ hoveredWaypoint, setHoveredWaypoint ] = useState<null | Feature<Point>>(null);
  const [ trailWaypoints, setTrailWaypoints ] = useState<Array<Feature<Point>>>([]);

  const medWidth = useMediaQuery({ query: '(max-width: 800px)' });
  const smallWidth = useMediaQuery({ query: '(max-width: 400px)' });
  const screenSize = getScreenSize(smallWidth, medWidth);

  const trailMapStyle = {
    height: smallWidth ? '40vh' : '100vh',
    width: smallWidth ? '100%' : `calc(100% - ${medWidth ? MED_SCREEN_IMG_GALLERY_WIDTH : DEFAULT_IMG_GALLERY_WIDTH}px)`,
    display: smallWidth ? 'block' : 'inline-block',
  };

  /*
   * NB for mobile: Chrome's disappearing URL bar means 100vh won't be full screen.
   * But fixed elements remain relative to the current screen
   * More info: https://developers.google.com/web/updates/2016/12/url-bar-resizing
   */
  const TIMELINE_CONSTANTS: CSSProperties = {
    backgroundColor: 'rgba(53,54,58,1)'
  };
  const routeTimelineStyle: CSSProperties = !smallWidth ? {
    ...TIMELINE_CONSTANTS,
    position: 'fixed',
    bottom: '20px',
    left: '30px',
    width: medWidth ? '200px' : '300px',
  } : {
    ...TIMELINE_CONSTANTS,
    display: 'block',
  };

  const imageGalleryStyle = {
    height: smallWidth ? 'calc(60vh - 10px)' : '100vh',
    width: smallWidth ? '100%' : `${medWidth ? MED_SCREEN_IMG_GALLERY_WIDTH : DEFAULT_IMG_GALLERY_WIDTH}px`,
    display: smallWidth ? 'block' : 'inline-block',
  };

  useEffect(() => {

    let ignore = false;

    const fetchTrailForView = async () => {
      const trail = await fetchTrail(trailId);
      const trailGeoJson = await fetchTrailGeoJson(trail.route_path);

      if (!ignore) {
        setTrail(trailGeoJson);
        const coords = trailGeoJson.features.find(_ => _.properties?.name === 'trail') as Feature<LineString>;
        // @ts-ignore
        const trailWaypoints = buildTrailWaypoints(coords.geometry.coordinates);
        setTrailWaypoints(trailWaypoints);
      }

    };

    const fetchTrailImagesForView = async () => {
      const fetchedTrailImages = await fetchTrailImages(trailId);

      if (!ignore) {
        setTrailImages(fetchedTrailImages);
      }

    };

    const loadTrail = async () => {
      // The order in which we draw the layers specifies the z-index on Mapbox.
      await fetchTrailForView();
      await fetchTrailImagesForView();
    };

    loadTrail();

    return () => { ignore = true; };

  }, [trailId]);

  const onMarkerClick = (markerId: string): void => {
    gaLogEvent({ category: 'trail-view', action: 'marker-click' });
    setClickedMarkerId([markerId]);
  };

  const onMarkerHover = (markerId: string): void => {
    setHoveredMarkerId(markerId);
  };

  const onImageHover = (imageId: string): void => {
    setHoveredMarkerId(imageId);
  };

  const onImageClick = (imageId: string): void => {
    gaLogEvent({ category: 'trail-view', action: 'image-click' });
    const clickedImage = trailImages.find(img => img.id === imageId);
    if (clickedImage) {
      setForcedLngLat([clickedImage.lon, clickedImage.lat]);
    }
  };

  const onImageExpand = (imageId: string): void => {
    gaLogEvent({ category: 'trail-view', action: 'full-screen-click' });
    const imageIndex = trailImages.findIndex(_ => _.id === imageId);
    setFullscreenImageIndex(imageIndex);
    setShowFullScreen(true);
  };

  const onFullScreenClose = (): void => {
    setShowFullScreen(false);
    setFullscreenImageIndex(0);
  };

  const imagePaths = trailImages.map(_ => {
    return Object.assign({}, _, {
      path: `${process.env.REACT_APP_STORAGE}${_.path}thumbnail.jpg`
    });
  });

  const onTrailHover = useCallback((lngLat: LngLat | null): void => {
    if (lngLat === null) {
      setHoveredWaypoint(null);
    } else {
      setHoveredWaypoint(findClosestPointToLngLat([lngLat.lng, lngLat.lat], trailWaypoints));
    }
  }, [trailWaypoints]);

  return (
    <div style={{
      height: '100vh',
      width: '100%',
    }}>
      <ImageOverlay
        show={showFullScreen}
        screenSize={screenSize}
        onCloseButtonClick={() => onFullScreenClose()}
        images={trailImages}
        initialStartingImageIndex={fullscreenImageIndex}

        trailGeoJson={trail}
        markers={trailImages}
      />
      <TrailMap
        style={{
          ...trailMapStyle,
          position: 'relative' // Setting this initially allows the Joystick to position. Mapbox also sets this later.
        }}
        showJoystick={!medWidth}
        joystickStyle={{
          position: 'absolute',
          top: '75px',
          left: 'calc(100% - 120px)',
          height: '100px',
          width: '100px',
        }}
        screenSize={screenSize}
        trailGeoJson={trail}
        markers={trailImages}
        hoveredMarkerId={hoveredMarkerId}
        forcedLngLat={forcedLngLat}
        onMarkerClick={onMarkerClick}
        onMarkerHover={onMarkerHover}
        onMarkerHoverLeave={() => onMarkerHover('')}
        onTrailHover={onTrailHover}
        hoveredWaypoint={hoveredWaypoint}
      />
      { // I haven't found a way to add this into the ImageGallery just as a margin
        smallWidth &&
          <div
            style={{
              backgroundColor: 'rgba(53,54,58,1)',
              height: '10px',
              width: '100vw'
            }}
          />
      }
      {
        trailWaypoints && trailWaypoints.length > 0 &&
        <RouteTimeline
          style={{
           ...routeTimelineStyle
          }}
          small={medWidth}
          panToWaypoint={() => {
            console.log('TrailView RouteTimeline onClick');
            if (hoveredWaypoint) {
              setForcedLngLat([hoveredWaypoint.geometry.coordinates[0], hoveredWaypoint.geometry.coordinates[1]]);
            }
          }}
          trailWaypoints={trailWaypoints}
          showWaypoint={hoveredWaypoint}
          onRouteHoverCloseToWaypoint={setHoveredWaypoint}
          mobileTouchHandlers={medWidth}
        />
      }
      <ImageGallery
        style={{
          ...imageGalleryStyle,
        }}
        images={imagePaths}
        clickedMarkerId={clickedMarkerId}
        hoveredMarkerId={hoveredMarkerId}
        onImageHover={onImageHover}
        onImageHoverLeave={() => onImageHover('')}
        onImageClick={onImageClick}
        onImageExpand={onImageExpand}
        screenSize={screenSize}
      />
    </div>
  );
};
