import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Feature, Point } from 'geojson';
import { Slider } from './components/Slider';

interface Props {
  style: CSSProperties;
  small: boolean;
  mobileTouchHandlers: boolean;
  showWaypoint: Feature<Point> | null;
  trailWaypoints: Array<Feature<Point>>;
  onRouteHoverCloseToWaypoint: (a: Feature<Point> | null) => void;
  panToWaypoint: () => void;
}

const LARGE_STYLES = {
  boxHeight: '70px',
  sliderHeight: 25,
  distancesHeight: '25px',
  distancesFontSize: '14px',
};
const SMALL_STYLES = {
  boxHeight: '40px',
  sliderHeight: 15,
  distancesHeight: '15px',
  distancesFontSize: '10px',
  distancesLineHeight: '19px'
};

export const RouteTimeline = ({
  small,
  style,
  mobileTouchHandlers,
  panToWaypoint,
  showWaypoint,
  trailWaypoints,
  onRouteHoverCloseToWaypoint,
}: Props) => {

  const [ sliderPosition, setSliderPosition ] = useState<number | null>(0);

  const findWaypoint = useCallback((percentageOnTimeline) => {
    if (trailWaypoints) {
      const fullDistance = trailWaypoints[trailWaypoints.length - 1].properties?.distance;
      const hoveredOnDistance = fullDistance * percentageOnTimeline;
      return trailWaypoints.find(_ => _.properties?.distance > hoveredOnDistance);
    }
  }, [trailWaypoints]);

  const highlightWaypoint = useCallback((percentageOnTimeline) => {
    const trailWaypoint = findWaypoint(percentageOnTimeline);
    if (trailWaypoint) {
      onRouteHoverCloseToWaypoint(trailWaypoint);
    }
  }, [findWaypoint, onRouteHoverCloseToWaypoint]);

  const clearHighlightedWaypoint = useCallback(() => {
    onRouteHoverCloseToWaypoint(null);
  }, [onRouteHoverCloseToWaypoint]);

  useEffect(() => {
    if (showWaypoint && trailWaypoints) {
      const fullDistance = trailWaypoints[trailWaypoints.length - 1].properties?.distance;
      const showWaypointDistance = showWaypoint.properties?.distance;
      setSliderPosition(showWaypointDistance / fullDistance);
    } else {
      setSliderPosition(null);
    }
  }, [showWaypoint, trailWaypoints]);

  return (
    <div
      style={{
        ...style,
        height: small ? SMALL_STYLES.boxHeight : LARGE_STYLES.boxHeight,
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Slider
        onHover={highlightWaypoint}
        onTouchStart={(e) => { highlightWaypoint(e); panToWaypoint(); } }
        onTouchMove={(e) => { highlightWaypoint(e); panToWaypoint(); } }
        onLeave={clearHighlightedWaypoint}
        onClick={panToWaypoint}
        position={sliderPosition}
        markerSize={ mobileTouchHandlers ? 50 : 25}
        height={ small ? SMALL_STYLES.sliderHeight : LARGE_STYLES.sliderHeight}
        mobile={mobileTouchHandlers}
      />
      <div
        style={{
          fontSize: small ? SMALL_STYLES.distancesFontSize : LARGE_STYLES.distancesFontSize ,
          height: small ? SMALL_STYLES.distancesHeight : LARGE_STYLES.distancesHeight,
          lineHeight: small ? SMALL_STYLES.distancesLineHeight : 'default',
          width: '100%',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div> 0 km </div>
        { (showWaypoint && trailWaypoints) &&
          <div>
            { ((showWaypoint.properties?.distance) / 1000).toFixed(1) } km
          </div>
        }
        <div>
          { (trailWaypoints[trailWaypoints.length - 1]?.properties?.distance / 1000).toFixed(1) } km
        </div>
      </div>
    </div>
  );

};
