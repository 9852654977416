import React, { MutableRefObject, useCallback, useEffect, useState, useRef } from 'react';
import nipplejs from 'nipplejs';

interface Props {
  onEvent?: MutableRefObject<(x: number, y: number) => void>;
  style: Object;
}

export const Joystick = (props: Props) => {

  const [ controller, setController ] = useState<nipplejs.JoystickManager | null>(null);
  const xIntensity = useRef(0);
  const yIntensity = useRef(0);
  const isActive = useRef(false);
  const nippleElement = useRef(null);

  const { onEvent } = props;
  const start = useCallback((e: nipplejs.EventData, data: nipplejs.JoystickOutputData) => {
    isActive.current = true;
  }, []);
  const move = useRef((e: nipplejs.EventData, data: nipplejs.JoystickOutputData) => {
    xIntensity.current = data.force * Math.cos(data.angle.radian);
    yIntensity.current = data.force * Math.sin(data.angle.radian);
  });
  const end = useCallback((e: nipplejs.EventData, data: nipplejs.JoystickOutputData) => {
    isActive.current = false;
    xIntensity.current = 0;
    yIntensity.current = 0;
  }, []);


  useEffect(() => {

    let controller: nipplejs.JoystickManager;

    controller = nipplejs.create({
      zone: nippleElement.current || undefined, // The undefined here is a hack to make the type system work.
      mode: 'static',
      position: { left: '50%', top: '50%' },
    });
    setController(controller);

    return () => {
      if (controller) {
        controller.destroy();
      }
      setController(null);
    };

  }, []);

  useEffect(() => {

    const intervalId = setInterval(() => {
      if (onEvent && onEvent.current && isActive.current) {
        onEvent.current(xIntensity.current, yIntensity.current);
      }
    }, 25);

    return () => {
      clearInterval(intervalId);
    };

  }, [onEvent]);

  useEffect(() => {

    const moveCb = move.current;

    if (controller) {
      controller.on('start', start);
      controller.on('move', moveCb);
      controller.on('end', end);
    }

    return () => {
      if (controller) {
        controller.off('start', start);
        controller.off('move', moveCb);
        controller.off('end', end);
      }
    };

  }, [controller, start, move, end]);

  return (<div
    ref={nippleElement}
    style={props.style}
  />);

};
