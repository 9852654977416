import { Feature, Point } from 'geojson';
import turfDistance from '@turf/distance';
import { point } from '@turf/helpers';

export const isNullish = (val: any): boolean => {
  return val === undefined || val === null;
};

export const generateRandomString = () => {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
};

// For me to effectively create this, I'll need access to the GPX file which recorded a timestamp at each point
const EARTH_CIRCUMFERENCE_AT_EQUATOR = 40007860; // meter
const getCircumferenceAtLat = (lat: number) => {
  lat = lat * (Math.PI / 180); // Convert to radians
  return EARTH_CIRCUMFERENCE_AT_EQUATOR * Math.cos(lat);
};

export const distance = (lng1: number, lat1: number, lng2: number, lat2: number): number => {
  const from = point([lng1, lat1]);
  const to = point([lng2, lat2]);
  return turfDistance(from, to, { units: 'meters' });
};

export const findClosestPointToLngLat = (
  coords: [number, number], features: Array<Feature<Point>>): null | Feature<Point> =>
{

  const xDegreeLngPerMeter = 360 / getCircumferenceAtLat(coords[1]);
  const yDegreeLatPerMeter = 360 / EARTH_CIRCUMFERENCE_AT_EQUATOR;
  const yOffset = yDegreeLatPerMeter * 50;
  const xOffset = xDegreeLngPerMeter * 50;
  const latMin = coords[1] - yOffset;
  const latMax = coords[1] + yOffset;
  const lngMin = coords[0] - xOffset;
  const lngMax = coords[0] + xOffset;

  // First, filter for only the waypoints in the closest 20m
  const waypoints = features.filter(feature => (
    feature.geometry.coordinates[0] > lngMin && feature.geometry.coordinates[0] < lngMax &&
      feature.geometry.coordinates[1] > latMin && feature.geometry.coordinates[1] < latMax
  ));

  if (waypoints.length === 0) {
    return null;
  }

  // NAIVE CLOSEST WAYPOINT METHOD
  const waypointsWithDistances = waypoints.map(waypoint => ({
    waypoint,
    distance: distance(coords[0], coords[1], waypoint.geometry.coordinates[0], waypoint.geometry.coordinates[1])
  }));

  const closestWaypoint = waypointsWithDistances.reduce((acc, el) => {
    return el.distance < acc.distance ? el : acc;
  });

  return closestWaypoint.waypoint;

  // SEGMENT METHOD:
  // Build a list of all features with it's next and previous waypoints
  // Then filter the list so that there are only unique pairs

  // Order these segments in a way that would help you find the most probable segment first.
  // A good start is to use the waypoints closest to you.

  // Then for each waypoint pair:
  //   1. calculate all the segments
  //   2. Figure out if the current lat/lng is potentially on the segments between the two points.

};

export const buildTrailWaypoints = (points: Array<[number, number]>): Array<Feature<Point>> => {
  let accDistance = 0;
  return points.map((el, i, arr) => {

    if (i > 0) {
      accDistance = accDistance + distance(el[0], el[1], arr[i - 1][0], arr[i - 1][1]);
    }

    return {
      'type': 'Feature',
      'properties': {
        id: i,
        distance: accDistance,
      },
      'geometry': {
        'type': 'Point',
        'coordinates': el
      }
    };

  });
};
