import { AiOutlineDrag, AiOutlineLine } from 'react-icons/ai';
import React from 'react';

interface Props {
  onMinimizeClick: () => void;
}

export const MinimizedMapHeader = ({
  onMinimizeClick
}: Props) => {

  return (
    <div
      style={{
        height: '24px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: '8px',
        paddingRight: '8px'
      }}
    >
          <span
            style={{
              flex: '1 0 0'
            }}
          >
            Map
          </span>
      <AiOutlineDrag
        id="draggable_header"
        size="20px"
        style={{
          marginRight: '8px'
        }}
      />
      <AiOutlineLine
        size="20px"
        onClick={onMinimizeClick}
      />
    </div>
  );

};
