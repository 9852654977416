import { Trail, TrailImage } from './trailepics-api.models';

export const fetchTrail = async (trailId: string): Promise<Trail> => {
  const trailResponse = await fetch(`${process.env.REACT_APP_TRAILEPICS_API}api/trails/${trailId}/`);
  return (await trailResponse.json());
};

export const fetchTrailImages = async (trailId: string): Promise<TrailImage[]> => {
  const trailImagesResponse = await fetch(
    `${process.env.REACT_APP_TRAILEPICS_API}api/trailimages/?trail_id=${trailId}&version=1`
  );
  return (await trailImagesResponse.json() as TrailImage[]).map(_ => {
    return Object.assign({}, _, { id: _.id.toString() });
  });
};
