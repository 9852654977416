import styled from 'styled-components';

export const FullScreenIconDiv = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  svg {
    fill: #aaa
  }
  :hover {
    background-color: rgba(255, 255, 255, 0.4);
    svg {
      fill: #fff
    }
  }
`;
