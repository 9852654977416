import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { TrailImage } from '../../apis/trailepics-api.models';
import { ScreenSize } from '../../App.viewmodels';
import { GalleryImage } from './components/GalleryImage/GalleryImage';

const SIDEBAR_MARGIN_LR = 10;
// All our thumbnails will be the default landscape photo ratio of w:h of 4:3

interface Props {
  screenSize: ScreenSize,
  images: TrailImage[],
  clickedMarkerId: [string],
  hoveredMarkerId: string,
  onImageHover: (id: string) => void;
  onImageHoverLeave: () => void;
  onImageClick: (id: string) => void;
  onImageExpand: (id: string) => void;
  style: Object;
}

const ImageDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;



export const ImageGallery = (props: Props & React.HTMLAttributes<any>) => {

  useEffect(() => {
    scroller.scrollTo(props.clickedMarkerId[0], {
      containerId: 'image-galley-id',
      duration: 1000,
      /*
       * ScrollTo does not work on mobile with smooth: true
       * https://github.com/fisshy/react-scroll/issues/62
       */
      smooth: !props.screenSize.medium,
      offset: -10, // To show the full box shadow
    });
  }, [props.clickedMarkerId, props.screenSize]);

  return (
    <div
      id='image-galley-id'
      style={
        {
          ...props.style,
          overflowY: 'scroll' as 'scroll', // TS compiler seeing this as string, so casting to type scroll
          backgroundColor: 'rgba(53,54,58,1)'
        }
      }
    >
      {
        props.images.map(image => (
          <Element
            name={image.id}
            key={image.id}
            onMouseEnter={() => props.onImageHover(image.id)}
            onMouseLeave={() => props.onImageHoverLeave()}
            onClick={() => props.onImageClick(image.id)}
          >
            <ImageDiv
              style={{
                margin: `${SIDEBAR_MARGIN_LR}px`
              }}
            >
              <GalleryImage image={image} hoveredMarkerId={props.hoveredMarkerId} onImageExpand={props.onImageExpand} />
            </ImageDiv>
          </Element>
        ))
      }
    </div>
  );
};

