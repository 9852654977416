import React, { useCallback, useEffect, useState } from 'react';
import {
  AiOutlineClose,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';
import { TrailImage } from '../../apis/trailepics-api.models';
import styled from 'styled-components';
import { TrailMap, TrailMarker } from '../trail-map/TrailMap';
import { FeatureCollection } from 'geojson';
import { DraggableContainer } from '../draggable-container/DraggableContainer';
import { MinimizedMapHeader } from './components/MinimizedMapHeader/MinimizedMapHeader';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { ScreenSize } from '../../App.viewmodels';
import { gaLogEvent } from '../../apis/analytics';

const CONTROLS_Z_INDEX = 1001;


const getImagePath = (images: TrailImage[], imageIndex: number) => {
  return `${process.env.REACT_APP_STORAGE}${images[imageIndex].path}full.jpg`;
};

/*
 * For the overlay map, when setting it with position: absolute's and bottom & right properties
 * it seems to use that to calculate and set top and left properties.
 * For the to properly control the positioning, it's easier to just overwrite top and left.
 * So I've opted to work with them directly, even it now I can't just assign pixels but have to use calc
 */

const MINIMIZED_MAP_STYLE = {
  position: 'absolute',
  top: 'calc(100vh - 78px)', // Aiming to have the bottom 50px bottom browser edge
  left: '50px',
  height: '30px',
  width: '120px',
  borderRadius: '20px',
  paddingTop: '2px',
  backgroundColor: 'white',
  zIndex: CONTROLS_Z_INDEX,
};

const MAXIMIZED_MAP_STYLE = {
  position: 'absolute',
  top: 'calc(100vh - 350px)',  // Aiming to have the bottom 50px bottom browser edge
  left: '50px',
  height: '300px',
  width: '300px',
  borderRadius: '2px',
  backgroundColor: 'white',
  zIndex: CONTROLS_Z_INDEX,
};

interface Props {
  show: boolean;
  screenSize: ScreenSize;
  onCloseButtonClick: () => void;
  images: TrailImage[];
  initialStartingImageIndex: number;

  trailGeoJson: FeatureCollection | null,
  markers: TrailMarker[],
}

/*
 * This component is shows full-resolution images on the screen.
 * We're not destroying and recreating it, for a few reasons:
 * 1) Most importantly, then we only need a single instance of the map, and we're paying for every instance.
 * 2) We don't have to do it, but we have to option of optimising by pre-loading the images
 * 3) We also don't have to do this, but this allows us to do animations when the component is destroyed.
 */

const OverlayDiv = styled.div`
  position: fixed; /* Sit on top of the page content */
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9); /* Black background with opacity */
  z-index: 1000;
`;

// Should this be a button?
const IconButton = styled.button`
  outline: none; // Disable the accessibility outline
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${CONTROLS_Z_INDEX};
  svg {
    fill: #aaa
  }
  :hover {
    background-color: rgba(255, 255, 255, 0.4);
    svg {
      fill: #fff
    }
  }
  :active {
    background-color: rgba(255, 255, 255, 0.6);
  }
  :disabled {
    svg {
      fill: #555
    }
    :hover {
      background-color: rgba(255, 255, 255, 0.1);
      svg {
        fill: #555
      }
    }
  }
`;

export const ImageOverlay = (props: Props) => {

  const {
    show,
    onCloseButtonClick,
    images,
    initialStartingImageIndex,
    trailGeoJson,
    markers,
  } = props;

  const [ imageIndex, setImageIndex ] = useState<number | null>(null);
  const [ mapMinimized, setMapMinimized ] = useState(false);
  const [ loadingIndicator, setLoadingIndicator ] = useState(false);
  const [ currentImageLoaded, setCurrentImageLoaded ] = useState(false);
  /*
   * We only want the child to react when we change this object, so we're using constants
   * If we use mapMinimized with a conditional that return a new style every render,
   * it means the child component sees a change on every render and re-applies the style, which is undesirable
   */
  const [ minimizedMapStyle, setMinimizedMapStyle ] = useState<Object>(MAXIMIZED_MAP_STYLE);
  const [ forcedLngLat, setForcedLngLat ] = useState<[number, number] | null>(null);
  const [ forcedZoom, setForcedZoom ] = useState<number | null>(null);
  const [ forcedPitch, setForcedPitch ] = useState<number | null>(null);
  const [ forcedBearing, setForcedBearing ] = useState<number | null>(null);

  const onNextImage = useCallback(
    (isForward: boolean) => {

      gaLogEvent({
        category: 'full-screen-view',
        action: isForward ? 'next-image-click' : 'previous-image-click'
      });

      setCurrentImageLoaded(false);
      setLoadingIndicator(false); // Ideally all loadingIndicator code is in one function, but it's "flashing"

      if (imageIndex !== null) {
        let nextImageIndex = imageIndex;
        if (isForward && imageIndex < images.length - 1) {
          nextImageIndex = imageIndex + 1;
        } else if (!isForward && imageIndex > 0) {
          nextImageIndex = imageIndex - 1;
        }
        setImageIndex(nextImageIndex);
        setForcedLngLat([images[nextImageIndex].lon, images[nextImageIndex].lat]);
      }
    },
    [imageIndex, images]
  );

  /*
   * As soon as the current image is loaded, update state to reflect that
   * Also start preloading the next image
   */
  const onImageLoad = useCallback(() => {
    setCurrentImageLoaded(true);

    let img: any;
    if (imageIndex !== null && imageIndex !== undefined && imageIndex < images.length - 2) {
      img = new Image();
      img.src = getImagePath(images, imageIndex + 1);
    }

    return () => {
      if (img) {
        img = null; // ensure GC can happen.
      }
    };

  }, [images, imageIndex]);

  /*
   * We don't want a loading indicator to show up immediately
   * So we delay showing an indicator with a barely perceptible duration
   * If it takes longer, then show the loading indicator
   */
  useEffect(() => {

    let timeoutId: any;

    if (imageIndex !== null && imageIndex !== undefined) {

      timeoutId = setTimeout(() => {
        setLoadingIndicator(true);
      }, 400);

    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  }, [images, imageIndex]);

  useEffect(() => {

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 39) {
        onNextImage(true);
      } else if (event.keyCode === 37) {
        onNextImage(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onNextImage]);

  // The hook that runs on overlay open/close
  useEffect(() => {
    if (show) {
      setForcedLngLat([images[initialStartingImageIndex].lon, images[initialStartingImageIndex].lat]);
      setForcedZoom(12.5);
      setForcedPitch(0);
      setForcedBearing(0);
      setImageIndex(initialStartingImageIndex);
    }

    return () => {
      setForcedLngLat(null);
      setForcedZoom(null);
      setForcedPitch(null);
      setForcedBearing(null);
      setCurrentImageLoaded(false);
      setImageIndex(null);
      setLoadingIndicator(false);
    };
  }, [show, images, initialStartingImageIndex]);

  return (
    <OverlayDiv
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        visibility: show ? 'visible' : 'hidden',
      }}
    >
      <IconButton
        onClick={() => onCloseButtonClick()}
        style={{
          right: '15px',
          top: '15px'
        }}
      >
        <AiOutlineClose size="32px"/>
      </IconButton>
      <IconButton
        onClick={() => onNextImage(false)}
        disabled={imageIndex === 0}
        style={{
          left: '15px',
          top: '50%'
        }}
      >
        <AiOutlineLeft size="32px"/>
      </IconButton>
      <IconButton
        onClick={() => onNextImage(true)}
        disabled={imageIndex === images.length - 1}
        style={{
          right: '15px',
          top: '50%'
        }}
      >
        <AiOutlineRight size="32px"/>
      </IconButton>
      {
        !props.screenSize.medium && (
          <DraggableContainer
            style={minimizedMapStyle}
            >
            <MinimizedMapHeader onMinimizeClick={() => {
            gaLogEvent({ category: 'full-screen-view', action: 'map-minimize-click' });
            setMinimizedMapStyle(mapMinimized ? MAXIMIZED_MAP_STYLE : MINIMIZED_MAP_STYLE);
            setMapMinimized(!mapMinimized);
          }} />
            <TrailMap
              screenSize={props.screenSize}
              style={ mapMinimized ?
                {
                  display: 'none',
                } :
                {
                  display: 'block',
                  height: '276px',
                  width: '300px',
                  border: '4px solid white',
                  borderTopWidth: '0px',
                }
              }
              showJoystick={false}
              trailGeoJson={trailGeoJson}
              markers={markers}
              hoveredMarkerId={imageIndex === null ? null : markers[imageIndex]?.id}
              forcedLngLat={forcedLngLat}
              forcedZoom={forcedZoom}
              forcedPitch={forcedPitch}
              forcedBearing={forcedBearing}
            />
          </DraggableContainer>
        )
      }
      {/* A container component to provide the image with a containing block against to set its height */}
      <div
        style={{
          height: 'calc(100vh - 50px)',
          width: 'calc(100vw - 100px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        {/* This Overlay is always on the DOM, to keep the map instance open */}
        {/* But if there is no valid image, or if it's not currently showing, don't render images */}
        {
          show && imageIndex !== null &&
          <img
            src={`${process.env.REACT_APP_STORAGE}${images[imageIndex].path}full.jpg`}
            onLoad={onImageLoad}
            alt='Enlarged'
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        }
        {
          show && imageIndex !== null && !currentImageLoaded && loadingIndicator &&
          <div
            style={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)',
              height: '100px',
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(50, 50, 50, 0.8)',
              borderRadius: '10px',
            }}
          >
            <UseAnimations
              animation={loading}
              strokeColor="white"
            />;
          </div>
        }
      </div>
    </OverlayDiv>
  );

};
