import React, { useState, useRef, useCallback, useEffect } from 'react';

interface Props {
  onHover?: (val: number) => void;
  onClick?: () => void;
  onLeave?: () => void;
  onTouchStart?: (val: number) => void;
  onTouchMove?: (val: number) => void;
  position?: number | null;
  markerSize: number;
  height: number;
  mobile: boolean;
}

const SLIDER_BAR_HEIGHT = 5;

export const Slider = ({
  height,
  mobile,
  onHover,
  onClick,
  onLeave,
  onTouchMove,
  onTouchStart,
  position,
  markerSize
}: Props) => {

  const [x, setX] = useState(0);
  const [show, setShow] = useState(false);
  const sliderBar = useRef(null);

  const mouseMove = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setX(e.nativeEvent.offsetX);
    if (sliderBar && sliderBar.current) {
      if (onHover) {
        // @ts-ignore
        onHover(e.nativeEvent.offsetX / sliderBar.current.offsetWidth);
      }
    }
  }, [onHover]);

  const _onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setShow(true);
    if (sliderBar.current) {
      // @ts-ignore
      const setAtDistance = e.nativeEvent.targetTouches[0].clientX - sliderBar.current.getBoundingClientRect().left;
      setX(setAtDistance);
      // @ts-ignore
      onTouchStart(setAtDistance / sliderBar.current.offsetWidth);
    }
  }, [onTouchStart]);

  const _onTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (sliderBar.current) {
      // @ts-ignore
      const setAtDistance = e.nativeEvent.targetTouches[0].clientX - sliderBar.current.getBoundingClientRect().left;
      setX(setAtDistance);
      // @ts-ignore
      onTouchMove(setAtDistance / sliderBar.current.offsetWidth);
    }
  }, [onTouchMove]);

  const _onLeave = useCallback(() => {
    setShow(false);
    if (onLeave) {
      onLeave();
    }
  }, [onLeave]);

  useEffect(() => {
    if (position !== null && position !== undefined) {
      setShow(true);
      // @ts-ignore
      setX(position * sliderBar.current.offsetWidth);
    } else {
      setShow(false);
    }
  }, [position]);

  return (
    <div
      style={{
        position: 'relative',
        height: `${height}px`,
        width: '100%',
      }}
    >
      {/* The horizontal solid timeline */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: `${SLIDER_BAR_HEIGHT}px`,
          backgroundColor: 'white',
          top: `${ height / 2 - SLIDER_BAR_HEIGHT / 2 }px`,
        }}
      />
      {/* The marker circle */}
      {
        show &&
        <div
          style={{
            position: 'absolute',
            width: `${markerSize}px`,
            height: `${markerSize}px`,
            borderRadius: '50%',
            backgroundColor: 'white',
            top: `${height / 2 - markerSize / 2}px`,
            left: `${x - markerSize / 2}px`,
          }}
        />
      }
      {/* The following div is an opaque overlay that just listens for the events */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0,
        }}
        ref={sliderBar}

        // Desktop behaviour
        onMouseEnter={() => { if (!mobile) { setShow(true); }  } }
        onMouseLeave={() => { if (!mobile) { _onLeave(); } } }
        onMouseMove={(e) => { if (!mobile) { mouseMove(e); } } }
        onClick={() => { if (!mobile) { onClick && onClick(); }  } }

        // Mobile behaviour
        onTouchStart={(e) => { if (mobile) { _onTouchStart(e); } } }
        onTouchMove={(e) => { if (mobile) { _onTouchMove(e); } } }
        onTouchEnd={() => { if (mobile) { _onLeave(); } } }
      />
    </div>
  );
};
