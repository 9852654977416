import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { TrailView } from './components/trail-view/TrailView';

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/:trailId">
          <TrailView />
        </Route>
      </Switch>
    </Router>

  );
}

export default App;
