import { Feature, FeatureCollection } from 'geojson';
import { TrailMarker } from '../trail-map/TrailMap';

export const buildTrailImagesToGeoJson = (images: TrailMarker[]): FeatureCollection => {
  const features: Feature[] = images.map(img => {
    return {
      type: 'Feature',
      geometry: {
        'type': 'Point',
        'coordinates': [img.lon, img.lat]
      },
      properties: {
        id: img.id,
      },
      id: img.id
    };
  });
  return {
    'type': 'FeatureCollection',
    'features': features
  };
};
