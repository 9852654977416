import React, { PropsWithChildren, useEffect } from 'react';
import * as CSS from 'csstype';
import { gaLogEvent } from '../../apis/analytics';

interface Props {
  style: CSS.Properties,
}

export const DraggableContainer = (props: PropsWithChildren<Props>) => {

  useEffect(() => {

    const draggableElement = document.getElementById('draggable');
    if (draggableElement) {
      dragElement(draggableElement);
    }

    function dragElement(element: HTMLElement) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      const draggableElementHeader = document.getElementById(element.id + '_header');
      if (draggableElementHeader) {
        // if present, the header is where you move the DIV from:
        draggableElementHeader.onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        element.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e: MouseEvent) {
        gaLogEvent({ category: 'full-screen-view', action: 'map-drag-click' });
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e: MouseEvent) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        element.style.top = (element.offsetTop - pos2) + 'px';
        element.style.left = (element.offsetLeft - pos1) + 'px';
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

  }, []);

  useEffect(() => {

    const draggableElement = document.getElementById('draggable');

    if (draggableElement) {
      Object.keys(props.style).forEach((style: any) => {
        draggableElement.style[style] = (props.style as any)[style] ;
      });
    }

  }, [props.style]);

  return (
    <div
      id="draggable"
    >
      {props.children}
    </div>
  );
};
