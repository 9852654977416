import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { Feature, FeatureCollection, Point } from 'geojson';
import { buildTrailImagesToGeoJson } from '../trail-view/convertMarkerToGeojson';
import { isNullish, generateRandomString } from '../../utilities/utilities';
import { Joystick } from '../joystick/joystick';
import { ScreenSize } from '../../App.viewmodels';

export interface TrailMarker {
  id: string;
  lon: number;
  lat: number;
}

const MAP_LAYER_TRAIL_ID = 'trail';
const MAP_INVISIBLE_TRAIL_ID = 'trail_invisible';
const MAP_SOURCE_TRAIL_IMAGES_ID = 'trail_images';
const MAP_LAYER_TRAIL_IMAGES_ID = 'trail_images_viz';
const MAP_LAYER_TRAIL_IMAGES_SELECTED_ID = 'trail_images_viz_selected';
const MAP_TRAIL_IMAGE_MARKER = 'map_marker';

const MAP_TRAIL_HOVERED_WAYPOINT_SOURCE_ID = 'trail_location_source';
const MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID = 'trail_location_layer';
const MAP_LOCATION_MARKER_ID = 'location_marker_id';

const TRAIL_COLOR = '#45aeff';
const MAX_PITCH_MOVEMENT_PER_TICK = 1;
const MAX_BEARING_MOVEMENT_PER_TIC = 2;

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoidGllbG1hbiIsImEiOiJja2ppejB4NGU1ZG5lMnNsZ2x4NjFrcHUxIn0.wT4xrTdhSpII1k0gpEXkZA';

const mapBoxLoadImage = (mapBoxMap: mapboxgl.Map, url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    mapBoxMap.loadImage(url, (error: any, image: any) => {
      if (error) {
        reject(error);
      }
      resolve(image);
    });
  });
};

interface Props {
  screenSize: ScreenSize;
  trailGeoJson: FeatureCollection | null,
  markers: TrailMarker[],
  hoveredMarkerId?: string | null,
  hoveredWaypoint?: Feature<Point> | null,
  forcedLngLat?: [number, number] | null,
  forcedZoom?: number | null,
  forcedPitch?: number | null,
  forcedBearing?: number | null,
  onMarkerClick?: (id: string) => void;
  onMarkerHover?: (id: string) => void;
  onMarkerHoverLeave?: () => void;
  onTrailHover?: (lngLat: LngLat | null) => void;
  style: Object;
  showJoystick?: boolean;
  joystickStyle?: Object;
}

const INITIAL_LAT = -33.95138;
const INITIAL_LNG = 18.40754;
const INITIAL_ZOOM = 12.5;
const INITIAL_BEARING = -169.60000;
const INITIAL_PITCH = 63.99999;

export const TrailMap = (props: Props & React.HTMLAttributes<any>) => {

  const mapContainerRef = useRef(null);

  const [ map, setMap ] = useState<any>(null);
  const setLat = useState(0)[1];
  const setLng = useState(0)[1];
  const setZoom = useState(0)[1];
  const [ bearing, setBearing ] = useState(0);
  const [ pitch, setPitch ] = useState(0);
  const [ isHoveringOnTrail, setIsHoveringOnTrail ] = useState(false);
  const [ isHoveringOnTrailWaypoint, setIsHoveringOnTrailWaypoint ] = useState(false);

  const { onMarkerHover, onMarkerHoverLeave, onMarkerClick, screenSize, onTrailHover } = props;
  useEffect(() => {

    let initialAnimationInProgress = false;
    let animationIndex = 1;

    const mapBoxMap = new mapboxgl.Map({
      container: mapContainerRef.current as unknown as string, // Mapbox typing does not allow for null
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [24.640586, -4.375874], // center of africa
      zoom: 1
    });

    // Declare initial animations
    const INITIAL_ANIMATIONS = [
      () => mapBoxMap.flyTo({
        center: [INITIAL_LNG, INITIAL_LAT],
        zoom: INITIAL_ZOOM - 2,
        speed: 0.8,
      }),
      () => mapBoxMap.easeTo({
        bearing: INITIAL_BEARING,
        pitch: INITIAL_PITCH,
        zoom: INITIAL_ZOOM,
        duration: 2000,
      })
    ];

    // Controls
    const nav = new mapboxgl.NavigationControl({
      showCompass: false,
      showZoom: !screenSize.medium,
    });
    mapBoxMap.addControl(nav, 'top-right');

    mapBoxMap.on('load', async () => {

      const [imageMarker, locationMarker] = await Promise.all([
        mapBoxLoadImage(mapBoxMap, `${process.env.PUBLIC_URL}/assets/images/map_marker.png`),
        mapBoxLoadImage(mapBoxMap, `${process.env.PUBLIC_URL}/assets/images/map_location.png`)
      ]);

      mapBoxMap.addImage(MAP_TRAIL_IMAGE_MARKER, imageMarker, { 'sdf': true });
      mapBoxMap.addImage(MAP_LOCATION_MARKER_ID, locationMarker, { 'sdf': true });
      setMap(mapBoxMap);

      // Enable 3D terrain
      mapBoxMap.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      });
      mapBoxMap.setTerrain({ 'source': 'mapbox-dem' });

      // Sky Layer:
      // https://docs.mapbox.com/mapbox-gl-js/example/atmospheric-sky/
      mapBoxMap.addLayer({
        'id': 'sky',
        // @ts-ignore (This property is not yet added to the Mapbox typings)
        'type': 'sky',
        'paint': {
          // @ts-ignore (This property is not yet added to the Mapbox typings)
          'sky-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 0,
            5, 0.3,
            8, 1
          ],
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0, 0],
          'sky-atmosphere-sun-intensity': 5
        }
      });

      mapBoxMap.addSource(MAP_TRAIL_HOVERED_WAYPOINT_SOURCE_ID, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [0, 0]
          }
        }
      });
      mapBoxMap.addLayer( {
        'id': MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID,
        'type': 'symbol',
        'source': MAP_TRAIL_HOVERED_WAYPOINT_SOURCE_ID,
        'layout': {
          'icon-image': MAP_LOCATION_MARKER_ID,
          'icon-size': 0.5,
          'icon-allow-overlap': true
        },
        'paint': {
          'icon-color': 'white',
        }
      });
      // Don't not show any markers initially, i.e. pick an expression that will resolve to false
      mapBoxMap.setFilter(MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID, [
        'in', 1, ['literal', []]
      ]);

      // Animations to get user to position
      setTimeout(() => {
        initialAnimationInProgress = true;
        INITIAL_ANIMATIONS[0]();
      }, 1000);

    });

    // Trigger successive initial animation
    mapBoxMap.on('moveend', function() {
      if (initialAnimationInProgress) {
        INITIAL_ANIMATIONS[animationIndex]();
        animationIndex += 1;
        if (animationIndex === INITIAL_ANIMATIONS.length) {
          initialAnimationInProgress = false;
        }
      }
    });
    // Unless interrupted by the user, then stop the animation.
    mapBoxMap.on('click', function() {
      if (initialAnimationInProgress) {
        initialAnimationInProgress = false;
      }
    });

    // If the map gets moved, update local state
    mapBoxMap.on('move', () => {
      setLng(parseFloat(mapBoxMap.getCenter().lng.toFixed(4)));
      setLat(parseFloat(mapBoxMap.getCenter().lat.toFixed(4)));
      setZoom(parseFloat(mapBoxMap.getZoom().toFixed(2)));
      setBearing(mapBoxMap.getBearing());
      setPitch(mapBoxMap.getPitch());
    });

    // Trail Image event listeners
    const markerMouseMoveFunction = (
      e: mapboxgl.MapMouseEvent & {features?: mapboxgl.MapboxGeoJSONFeature[] | undefined} & mapboxgl.EventData
    ) => {
      mapBoxMap.getCanvas().style.cursor = 'pointer';
      if (e && e.features && e.features.length > 0) {
        let newHoveredMarkerId = (e.features[0].id as number).toString();
        onMarkerHover && onMarkerHover(newHoveredMarkerId);
      }
    };
    const markerMouseLeaveFunction = () => {
      onMarkerHoverLeave && onMarkerHoverLeave();
      mapBoxMap.getCanvas().style.cursor = '';
    };
    const markerMouseClick = (
      e: mapboxgl.MapMouseEvent & {features?: mapboxgl.MapboxGeoJSONFeature[] | undefined} & mapboxgl.EventData
    ) => {
      if (e && e.features && e.features.length > 0) {
        let clickedMarkerId = (e.features[0].id as number).toString();
        onMarkerClick && onMarkerClick(clickedMarkerId);
      }
    };
    const markerTouchEnd = (
      e: mapboxgl.MapTouchEvent & {features?: mapboxgl.MapboxGeoJSONFeature[] | undefined} & mapboxgl.EventData
    ): void => {
      if (mapBoxMap) {
        const features = mapBoxMap.queryRenderedFeatures(e.point, {
          layers: [MAP_LAYER_TRAIL_IMAGES_ID]
        });
        if (features && features.length > 0 && features[0].properties) {
          let touchedMarkerId = (features[0].properties.id as number).toString();
          onMarkerHover && onMarkerHover(touchedMarkerId);
          onMarkerClick && onMarkerClick(touchedMarkerId);
        }
      }
    };
    if (!screenSize.medium) {
      // Even if the markers on the second layer pops up, the functionality should remain the same.
      mapBoxMap.on('mousemove', MAP_LAYER_TRAIL_IMAGES_ID, markerMouseMoveFunction);
      mapBoxMap.on('mousemove', MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, markerMouseMoveFunction);
      mapBoxMap.on('mouseleave', MAP_LAYER_TRAIL_IMAGES_ID, markerMouseLeaveFunction);
      mapBoxMap.on('mouseleave', MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, markerMouseLeaveFunction);
      /*
       * Functionality: When clicking on a marker, scroll the image gallery to the correct photo
       * NOTE: We don't listen to clicks on the MAP_LAYER_TRAIL_IMAGES_ID layer
       * This is because the clicks we're interested in will always happen on the hovered markers (on desktop,
       * mobile works differently since it does not have hover)
       * Since mapBox draws to a canvas, click events don't expose the simple e.stopPropagation, meaning clicking
       * where there is a bunch of features can trigger multiple events from multiple features, so you click the top
       * layer and it ends up also registering a click on features underneath if those layers are listening for it.
       * https://stackoverflow.com/questions/57477898/stop-event-propagation-of-mapbox-layers
       */
      mapBoxMap.on('click', MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, markerMouseClick);
    } else {
      mapBoxMap.on('touchend', MAP_LAYER_TRAIL_IMAGES_ID, markerTouchEnd);
    }
    /*
     * Clearing the map breaks Mapbox internal layer remove. Haven't been able to resolve this.
     */
    return () => {
      mapBoxMap.remove();
      setMap(null);
    };

  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  /*
   * Timeline functionality
   */
  useEffect(() => {
    const trailLocationMouseMoveFunction = (
      ev: mapboxgl.MapMouseEvent & mapboxgl.EventData
    ): void => {
      if (onTrailHover) {
        setIsHoveringOnTrail(true);
        onTrailHover(ev.lngLat);
      }
    };

    const trailLocationMouseLeaveFunction = (): void => {
      setIsHoveringOnTrail(false);
    };

    const trailWaypointMouseMoveFunction = (): void => {
      setIsHoveringOnTrailWaypoint(true);
    };

    const trailWaypointMouseLeaveFunction = (): void => {
      setIsHoveringOnTrailWaypoint(false);
    };

    if (map) {
      map.on('mousemove', MAP_INVISIBLE_TRAIL_ID, trailLocationMouseMoveFunction);
      map.on('mouseleave', MAP_INVISIBLE_TRAIL_ID, trailLocationMouseLeaveFunction);
      map.on('mousemove', MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID, trailWaypointMouseMoveFunction);
      map.on('mouseleave', MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID, trailWaypointMouseLeaveFunction);
    }

    return () => {
      if (map) {
        map.off('mousemove', trailLocationMouseMoveFunction);
        map.off('mouseleave', trailLocationMouseLeaveFunction);
        map.off('mousemove', trailWaypointMouseMoveFunction);
        map.off('mouseleave', trailWaypointMouseLeaveFunction);
      }
    };

  }, [map, onTrailHover]);

  const { hoveredWaypoint } = props;
  useEffect(() => {

    if (map && hoveredWaypoint) {
      map.getSource(MAP_TRAIL_HOVERED_WAYPOINT_SOURCE_ID).setData(hoveredWaypoint);
      map.setFilter(MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID, null);
    }

    return () => {
      if (map) {
        map.setFilter(MAP_TRAIL_HOVERED_WAYPOINT_LAYER_ID, [ 'in', 1, ['literal', []] ]);
      }
    };

  }, [map, hoveredWaypoint]);

  useEffect(() => {

    if (map && !isHoveringOnTrail && !isHoveringOnTrailWaypoint) {
      if (onTrailHover) {
        onTrailHover(null);
      }
    }

  }, [map, onTrailHover, isHoveringOnTrail, isHoveringOnTrailWaypoint]);

  const { trailGeoJson } = props;
  useEffect(() => {

    let geoJsonFeatureIds: string[] = [];

    if (map && trailGeoJson) {
      /*
       * One of the GeoJson features here will be a line, which is the trail.
       * The feature "trail" has been given a property "name" which is "trail"
       * Other features might include waypoints on the route (should probably ignore them for now)
       */
      trailGeoJson.features.forEach(feature => {
        // @ts-ignore
        const featureId = feature.properties.name || generateRandomString();
        map.addSource(featureId, {
          'type': 'geojson',
          'data': feature
        });
        geoJsonFeatureIds = [...geoJsonFeatureIds, featureId];
      });
      map.addLayer({
        'id': MAP_LAYER_TRAIL_ID,
        'type': 'line',
        'source': 'trail',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': TRAIL_COLOR,
          'line-width': 3
        }
      });
      map.addLayer({
        'id': MAP_INVISIBLE_TRAIL_ID,
        'type': 'line',
        'source': 'trail',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': 'rgba(0, 0, 0, 0)',
          // 'line-color': 'orange', // easy way to test good line-width thickness for interactions
          'line-width': 10
        }
      });

    }

    return () => {
      if (map) {
        if (map.getLayer(MAP_LAYER_TRAIL_ID)) {
          map.removeLayer(MAP_LAYER_TRAIL_ID);
        }
        geoJsonFeatureIds.forEach((id) => {
          map.removeSource(id);
        });
      }
    };

  }, [map, trailGeoJson]);


  const { markers } = props;
  useEffect(() => {

    if (map && markers) {

      let markersGeoJson = buildTrailImagesToGeoJson(markers);

      map.addSource(MAP_SOURCE_TRAIL_IMAGES_ID, {
        'type': 'geojson',
        'data': markersGeoJson,
      });

      map.addLayer({
        'id': MAP_LAYER_TRAIL_IMAGES_ID,
        'type': 'symbol',
        'source': MAP_SOURCE_TRAIL_IMAGES_ID,
        'layout': {
          'icon-anchor': 'bottom', // Gives the impression the pins point is pointing to where image was taken
          'icon-image': MAP_TRAIL_IMAGE_MARKER,
          'icon-size': 0.5,
          'icon-allow-overlap': true
        },
        'paint': {
          'icon-color': TRAIL_COLOR,
        }
      });

      map.addLayer({
        'id': MAP_LAYER_TRAIL_IMAGES_SELECTED_ID,
        'type': 'symbol',
        'source': MAP_SOURCE_TRAIL_IMAGES_ID,
        'layout': {
          'icon-anchor': 'bottom', // Gives the impression the pins point is pointing to where image was taken
          'icon-image': MAP_TRAIL_IMAGE_MARKER,
          'icon-size': 0.75,
          'icon-allow-overlap': true
        },
        'paint': {
          'icon-color': '#fff',
        }
      });

      // Don't not show any markers initially, i.e. pick an expression that will resolve to false
      map.setFilter(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, [
        'in',
        1,
        ['literal', []]
      ]);

    }

    return () => {
      if (map) {
        map.removeLayer(MAP_LAYER_TRAIL_IMAGES_ID);
        map.removeLayer(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID);
        map.removeSource(MAP_SOURCE_TRAIL_IMAGES_ID);
      }
    };

  }, [map, markers]);


  const { hoveredMarkerId } = props;
  useEffect(() => {

    // Account for the fact that markers might not yet be set, therefore the layers might not exist yet
    // Trying to set filters on layers that don't exist will throw errors.

    if (map && map.getLayer(MAP_LAYER_TRAIL_IMAGES_ID) && !isNullish(hoveredMarkerId)) {
      map.setFilter(MAP_LAYER_TRAIL_IMAGES_ID, [
        'match',
        ['get', 'id'],
        [hoveredMarkerId],
        false,
        true
      ]);
    }

    if (map && map.getLayer(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID) && !isNullish(hoveredMarkerId)) {
      map.setFilter(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, [
        'match',
        ['get', 'id'],
        [hoveredMarkerId],
        true,
        false
      ]);
    }

    return () => {
      if (map && map.getLayer(MAP_LAYER_TRAIL_IMAGES_ID)) {
        map.setFilter(MAP_LAYER_TRAIL_IMAGES_ID, null);
      }
      if (map && map.getLayer(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID)) {
        map.setFilter(MAP_LAYER_TRAIL_IMAGES_SELECTED_ID, null);
      }
    };

  }, [map, hoveredMarkerId]);

  const { forcedLngLat, forcedZoom, forcedPitch, forcedBearing } = props;
  useEffect(() => {
    let easeToPosition: any = {};
    if (map && forcedLngLat !== null && forcedLngLat !== undefined) {
      easeToPosition['center'] = forcedLngLat;
    }
    if (map && forcedZoom !== null && forcedZoom !== undefined) {
      easeToPosition['zoom'] = forcedZoom;
    }
    if (map && forcedPitch !== null && forcedPitch !== undefined) {
      easeToPosition['pitch'] = forcedPitch;
    }
    if (map && forcedBearing !== null && forcedBearing !== undefined) {
      easeToPosition['bearing'] = forcedBearing;
    }
    if (Object.keys(easeToPosition).length > 0) {
      map.easeTo(easeToPosition);
    }
  }, [map, forcedLngLat, forcedZoom, forcedPitch, forcedBearing]);

  const joyStickEvent = useRef<(x: number, y: number) => void>((x, y) => {});

  useEffect(() => {
    joyStickEvent.current = (x: number, y: number) => {
      const newCalculatedPitch = pitch - MAX_PITCH_MOVEMENT_PER_TICK * y;
      const newPitch = newCalculatedPitch > 85 ?
        85 :
        newCalculatedPitch < 0 ?
          0 :
          newCalculatedPitch;

      const newCalculatedBearing = (bearing - MAX_BEARING_MOVEMENT_PER_TIC * x) % 360;

      if (map) {
        map.jumpTo({
          pitch: newPitch,
          bearing: newCalculatedBearing,
        });
      }
      setPitch(newPitch);
      setBearing(newCalculatedBearing);
    };
  }, [map, pitch, bearing]);

  return (
    <div
      ref={mapContainerRef}
      style={props.style}
    >
      {
        props.showJoystick && <Joystick
          style={props.joystickStyle || {}}
          onEvent={joyStickEvent}
        />
      }
    </div>
  );

};
